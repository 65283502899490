import { constantLease } from '../edit/categorySelectOption';
import { isNil } from 'lodash-es';

export function judge(i: {
	attachmentUrls: {
		uuid: string | null;
		attachmentUrl: string;
		recordingTime: Date | null;
	}[];
	logs: {
		uuid: string;
		operateContent: any;
		userId: string;
		groupId: string;
		recordingTime: Date | null;
		updateTime: Date | null;
		apartmentUuid: string;
	}[];
	uuid: string;
	chargeStatus: string | null;
	roomNumber: string;
	category: string | null;
	checkOutTime: Date | null;
	checkInTime: Date | null;
	objectId: string | null;
	rent: string | null;
	deposit: string | null;
	electricMeterReadingLastMonth: string | null;
	electricMeterReadingThisMonth: string | null;
	coldWaterMeterReadingLastMonth: string | null;
	coldWaterMeterReadingThisMonth: string | null;
	hotWaterMeterReadingLastMonth: string | null;
	hotWaterMeterReadingThisMonth: string | null;
	networkFee: string | null;
	tvFee: string | null;
	cleanFee: string | null;
	remark: string | null;
	groupId: string;
	feeConfigSnapshotUuid: string;
	floorLevel: string | null;
	modifyId: string | null;
}) {
	let result = '有效';

	const verify = [
		{
			key: 'rent',
			name: '房租',
		},
		{
			key: 'electricMeterReadingLastMonth',
			name: '电表上月读数',
		},
		{
			key: 'electricMeterReadingThisMonth',
			name: '电表本月读数',
		},
		{
			key: 'hotWaterMeterReadingLastMonth',
			name: '热水水表上月读数',
		},
		{
			key: 'hotWaterMeterReadingThisMonth',
			name: '热水水表本月读数',
		},
		{
			key: 'coldWaterMeterReadingLastMonth',
			name: '冷水水表上月读数',
		},
		{
			key: 'coldWaterMeterReadingThisMonth',
			name: '冷水水表本月读数',
		},
	];

	for (const v of verify) {
		if (i[v.key] === undefined || i[v.key] === null) {
			// console.warn(i.room_number + ' ' + v.key + ' 数据缺失');
			result = '数据缺失';
		}
	}

	if (
		parseInt(i.electricMeterReadingLastMonth, 10) >
		parseInt(i.electricMeterReadingThisMonth, 10)
	) {
		result = '上月电表读数大于本月电表读数';
	}

	if (
		parseInt(i.hotWaterMeterReadingLastMonth, 10) >
		parseInt(i.hotWaterMeterReadingThisMonth, 10)
	) {
		result = '上月热水表读数大于本月热水表读数';
	}

	if (
		parseInt(i.coldWaterMeterReadingLastMonth, 10) >
		parseInt(i.coldWaterMeterReadingThisMonth, 10)
	) {
		result = '上月冷水读数大于本月冷水读数';
	}

	if (
		!(
			i?.remark?.includes('退房') ||
			i?.remark?.includes('待租') ||
			i?.remark?.includes('入住')
		)
	) {
		if (i.electricMeterReadingLastMonth === i.electricMeterReadingThisMonth) {
			result = '上月电表读数等于本月电表读数';
		}

		if (i.hotWaterMeterReadingLastMonth === i.hotWaterMeterReadingThisMonth) {
			result = '上月热水表读数等于本月热水表读数';
		}

		if (i.coldWaterMeterReadingLastMonth === i.coldWaterMeterReadingThisMonth) {
			result = '上月冷水读数等于本月冷水读数';
		}
	}

	if (i?.category === constantLease && isNil(i?.attachmentUrl)) {
		result += '; 单据尚未上传;';
	}

	return result;
}
