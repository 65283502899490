import { isNumber, isString } from 'lodash-es';
import Big from 'big.js';

export function bigConstruct(num?: Big | string | number | null) {
	if (Number.isNaN(num)) {
		return Big(0);
	}

	if (isNumber(num)) {
		return Big(num);
	}
	if (isString(num)) {
		return Big(num);
	}

	if (num instanceof Big) {
		return num;
	}

	return Big(0);
}
