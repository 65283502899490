import { isNil } from 'lodash-es';
import { bigConstruct } from './bigConstruct';

export function clacThisMonthLastMonth(
	thisMonth?: string | null,
	lastMonth?: string | null,
) {
	if (!isNil(thisMonth) && !isNil(lastMonth)) {
		return bigConstruct(thisMonth).sub(bigConstruct(lastMonth));
	}

	return bigConstruct(0);
}
